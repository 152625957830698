<!--
 * @Author: zhangyutong
 * @Date: 2021-09-01 19:10:08
 * @LastEditTime: 2022-04-13 17:58:59
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingInvitation\component\dialogInvitation.vue
-->
<!--  -->
<template>
  <div class="stepDialog">
    <el-dialog
      class="abow_dialog"
      width="70%"
      top="3%"
      style="height: 80%; overflow: visible"
      :title="dialogTitle"
      :visible.sync="isShowDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancel"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <!--步骤1-->
        <stepOne
          ref="oneStep"
          :editData="editData"
          v-show="currentStep === 1"
          @saveOneData="saveOneData"
        >
        </stepOne>
        <stepTwo
          ref="twoStep"
          v-show="currentStep === 2"
          @saveTowData="saveTowData"
        >
        </stepTwo>
        <stepThr
          ref="thrStep"
          v-show="currentStep === 3"
          @saveThrData="saveThrData"
        >
        </stepThr>
      </div>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <span class="step">
          <span>{{ currentStep }}</span>
          /
          <span style="margin-right: 20px">{{ totalStep }}</span>
        </span>
        <el-button
          v-show="currentStep !== 1"
          size="mini"
          type="primary"
          @click="preStep"
        >
          {{ $t("label.import.page1.laststep") }}
        </el-button>
        <el-button
          v-show="currentStep !== 3"
          size="mini"
          type="primary"
          @click="nextStep"
        >
          {{ $t("label.import.page1.nextstep") }}
        </el-button>
        <el-button
          v-show="currentStep === 3"
          size="mini"
          type="primary"
          @click="complete"
        >
          {{ $t("label.emailtocloudcc.button.complete") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import stepOne from "@/views/marketList/marketingInvitation/component/stepOne.vue";
import stepTwo from "@/views/marketList/marketingInvitation/component/stepTwo.vue";
import stepThr from "@/views/marketList/marketingInvitation/component/stepThr.vue";
import * as CommonObjApi from "../../api";

export default {
  components: { stepOne, stepTwo, stepThr },
  data() {
    return {
      isShowDialog: false,
      dialogTitle: this.$i18n.t("c471"),//"市场活动:员工邀约"
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      currentStep: 1,
      totalStep: 3,
      invitationData: {
        name: "",
        formid: "",
        campaignownerid: "",
        inviteduser: "",
        emailcontent: "",
        campaignid: "",
        fileId: "",
      },
      editData: {},
    };
  },
  //方法集合
  methods: {
    saveOneData(data) {
      this.invitationData.formid = data.fromid;
      this.invitationData.campaignownerid = data.user;
    },
    saveTowData(ids, names) {
      if (ids.length === 0) {
        // "请选择要邀约员工"
        this.$message.error(this.$i18n.t("c740"));
      } else {
        this.invitationData.name = names;
        this.invitationData.inviteduser = ids;
        this.currentStep = 3;
      }
    },
    saveThrData(template, fileId) {
      if (template.length === 0) {
        // "请填写邮件内容!"
        this.$message.error(this.$i18n.t("c741"));
      } else {
        this.invitationData.emailcontent = template;
        this.invitationData.fileId = fileId;
        this.saveInvitation();
      }
    },
    init(id) {
      this.isShowDialog = true;
      if (id) {
        this.currentStep = 1;
        CommonObjApi.getEmployeeInvitedInfo({ id: id }).then((res) => {
          if (res.result) {
            this.editData = res.data.formList[0];
            this.$refs.oneStep.init(this.editData);
            let obj = {
              id: this.editData.inviteduser,
            };
            this.$refs.twoStep.getViewListData([obj]);
            // this.$refs.thrStep.edit([obj])
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      } else {
        this.currentStep = 1;
        this.$nextTick(() => {
          this.$refs.twoStep.getViewListData();
        });
      }
    },
    preStep() {
      this.currentStep = this.currentStep - 1;
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.$refs.oneStep.$refs.fromFormat.validate((valid) => {
          if (valid) {
            this.$refs.oneStep.saveOne();
            this.currentStep = this.currentStep + 1;
          } else {
            return false;
          }
        });
      } else if (this.currentStep === 2) {
        this.$refs.twoStep.saveTow();
      }
    },
    complete() {
      this.$refs.thrStep.saveThr();
      this.clearcheck();
    },
    saveInvitation() {
      this.invitationData.campaignid =
        this.$route.params.id || this.$route.query.id;
      CommonObjApi.saveEmployeeInvited(this.invitationData).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("savesuccess"));//"保存成功"
          this.$emit("saveinvit");
          this.isShowDialog = false;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    cancel() {
      this.isShowDialog = false;
      this.currentStep = 1;
      this.clearcheck();
    },
    clearcheck() {
      this.invitationData = {
        name: "",
        formid: "",
        campaignownerid: "",
        inviteduser: "",
        emailcontent: "",
        campaignid: "",
        fileId: "",
      };
      this.$refs.oneStep.batalkan();
      this.$refs.twoStep.init();
      this.$refs.thrStep.fileId = "";
      this.$refs.thrStep.$refs.upload.clearFiles();
      this.$refs.thrStep.template = this.$i18n.t("user.email.template");
      this.$refs.thrStep.topsMessage = this.$i18n.t("label.file.noselect");
    },
  },
};
</script>
<style lang='scss' scoped>
</style>