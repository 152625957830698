<!--
 * @Author: zhangyutong
 * @Date: 2021-09-01 19:20:27
 * @LastEditTime: 2022-04-13 19:42:36
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingInvitation\component\stepOne.vue
-->
<!--  -->
<template>
  <div class="">
    <el-form
      :status-icon="true"
      ref="fromFormat"
      :model="invitationData"
      class="clearfix"
      :validate-on-rule-change="false"
      label-position="right"
      :rules="rules"
    >
      <div class="pull-left" style="width: 100%">
        <!-- 基本信息 -->
        <p class="groupTitle">{{$t('page.layout.binfo')}}</p>
      </div>
      <div class="input-box">
        <!-- 选择该海报应用的表单 -->
        <el-form-item
          :label="$t('c427')"
          label-width="200px"
          style="min-height: 38px; width: 100%"
          required
          prop="fromid"
        >
          <!-- 查找/查找多选 -->
          <el-select
            v-model="invitationData.fromid"
            filterable
            :placeholder="$t('label.searchs')"
            clearable
            class="no-suffix"
            style="width: 50%"
            @blur="blurEvent"
          >
            <p class="searchTipBox">
              <span
                @click="remoteFromSearch()"
                style="display: inline-block; width: 100%"
              >
                <i class="el-icon-search"></i>
                <span style="padding-left: 10px">
                  {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                </span>
              </span>
            </p>
            <el-option
              v-for="item in optionFromList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
            <el-option
              v-show="false"
              key="new"
              :label="$t('label.new')"
              value="新建"
            >
            </el-option>
            <p class="searchTipBox" @click="newSearchableFromField()">
              <i class="el-icon-plus"></i>
              <span style="padding-left: 10px">
                {{ $t("label.new") }}
              </span>
            </p>
          </el-select>
        </el-form-item>
        <!-- 市场活动所有人 -->
        <el-form-item
          :label="$t('label.all.marketing.activities')"
          label-width="200px"
          style="min-height: 38px; width: 100%"
          prop="user"
          required
        >
          <!-- 查找/查找多选 -->
          <el-select
            v-model="invitationData.user"
            filterable
            :placeholder="$t('label.searchs')"
            clearable
            style="width: 50%"
            @blur="blurEvent"
          >
          <!-- 市场活动所有人 -->
            <el-option :label="$t('label.all.marketing.activities')" value="campaignowner">
            </el-option>
            <!-- '邀约员工' -->
            <el-option :label="$t('c9')" value="inviteduser"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="mainText">
        {{$t('c473')}}
        <!-- 系统将为员工生成唯一邀约码，员工可使用邀约码进行邀约，并写计入个人绩效。请注意，潜在客户所有人将是当前市场活动所有人或者营销渠道邀约员工 -->
      </div>
      <div class="linkText">
        <!-- 您可以通过潜在客户分配规则进行重新分配。关于分配规则<a>请访问这里</a> -->
        {{$t('c474')}}
      </div>
    </el-form>
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showFromSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <searchFromTable
        ref="searchFromTable"
        @changeSelect="changeSlaveFromSelect"
      />
    </el-dialog>
  </div>
</template>

<script>
// import searchFromTable from '@/views/marketList/marketingPosters/component/searchFromTable.vue'
import searchFromTable from "@/views/marketList/marketingPosters/component/searchFromTable.vue";
export default {
  components: { searchFromTable },
  props: {
    editData: {
      type: Object,
      default:() => {},
    },
  },
  data() {
    return {
      showFromSearchTable: false,
      optionFromList: [],
      invitationData: {
        fromid: "",
        user: "",
      },
      rules: {
        fromid: [
          // "选择该海报应用的表单"
          { required: true, message: this.$i18n.t("c472"), trigger: "blur" },
        ],
        user: [
          // "请填写市场活动所有人"
          { required: true, message: this.$i18n.t("c475"), trigger: "blur" },
        ],
      },
    };
  },
  //方法集合
  methods: {
    //初始化
    batalkan() {
      this.optionFromList = [];
      this.invitationData = {
        fromid: "",
        user: "",
      };
      this.$refs.fromFormat.resetFields();
    },

    init(val) {
      if (val) {
        this.$refs.fromFormat.resetFields();
        this.invitationData.user = val.campaignownerid;
        this.invitationData.fromid = val.formid;
        this.optionFromList.push({
          label: val.formchannel,
          value: val.formid,
        });
      } else {
        this.batalkan();
      }
    },
    saveOne() {
      this.$emit("saveOneData", this.invitationData);
    },
    changeSlaveFromSelect(row) {
      this.optionFromList.push({
        label: row.data.channel,
        value: row.data.id,
      });
      this.invitationData.fromid = row.data.id.toString();
      this.showFromSearchTable = false;
    },
    remoteFromSearch() {
      this.showFromSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchFromTable.page = 1;
        this.$refs.searchFromTable.pageObj.dataList = [];
        this.$refs.searchFromTable.init();
      });
    },
    newSearchableFromField() {
      this.$router.push({
        path: "/marketForm/selectTemplate",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.mainText {
  font-size: 14px;
  font-weight: bold;
  margin-top: 100px;
  padding-right: 129px;
  padding: 0px 70px 0px 65px;
}
.linkText {
  font-size: 14px;
  font-weight: bold;
  padding-right: 129px;
  padding: 0px 70px 0px 65px;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  &:hover {
    background: #f5f7fa;
  }
}

::v-deep .no-suffix .el-input__suffix {
  display: none;
}
.input-box {
  padding-top: 80px;
  .prompt {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #6d7278;
  }
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
  width: 100%;
}
</style>