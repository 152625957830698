<template>
  <div class="top">
    <div class="pull-left" style="width: 100%">
      <!-- 选择要发送邀约码的员工 -->
      <p class="groupTitle">{{$t('c476')}}</p>
    </div>
    <div class="mainBox">
      <p class="headline">{{ $t("label.letter.body") }}：</p>
      <el-input type="textarea" v-model="template" :rows="10"> </el-input>
      <div class="updateBox">
        <p class="updatetitle">{{ $t("label.attachment") }}：</p>
        <el-upload
          class="upload-demo"
          ref="upload"
          :limit="1"
          :action="action"
          :show-file-list="true"
          :multiple="false"
          :before-upload="beforeUpload"
          :auto-upload="false"
          :on-change="onChange"
          :on-remove="onRemove"
          :on-exceed="onExceed"
        >
          <el-button slot="trigger" class="updateBt" size="mini">{{
            $t("label.tabpage.choosefilez")
          }}</el-button>
        </el-upload>
        <p class="tips">{{ topsMessage }}</p>
      </div>
      <div class="errMessage">{{ $t("label.file.size.limit") }}</div>
    </div>
  </div>
</template>
<script>
import * as CommonObjApi from "../../api";

export default {
  components: {},
  data() {
    return {
      topsMessage: this.$i18n.t("label.file.noselect"),
      action: '',
      size: 10240,
      id: '',
      userIds: '',
      fileId: '',
      template: this.$i18n.t('user.email.template')
    };
  },
  created() {
    this.userIds = this.$route.params.userIds
    this.id = this.$route.params.id
  },
  //方法集合  
  methods: {
    //编辑回显
    edit() {

    },
    onExceed() {
      this.$message.warning(this.$i18n.t('vue_label_file_num_limit_tip'))
    },
    onChange() {
      this.topsMessage = ''
    },
    onRemove() {
      this.topsMessage = this.$i18n.t("label.file.noselect")
    },
    //取消
    cancel() {
      this.$router.go(-1)
    },
    //触发保存邮箱
    saveThr() {
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$emit('saveThrData', this.template, this.fileId)
      } else {
        this.$refs.upload && this.$refs.upload.submit()
      }
    },
    // 文件上传前执行的事件
    beforeUpload(file) {
      let size = file.size / 1024
      if (size > this.size) {
        // 文件大小超过限制
        this.$message.error(file.name + this.$i18n.t('vue_label_file_size_limit_tip'))
        return false
      }
      let formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', file.name)
      formData.append('groupid', '')
      formData.append('libid', '')
      formData.append('parentid', '')
      formData.append('isFromEmail', '1')
      CommonObjApi.upload(formData).then(res => {
        if (res.result) {
          // 上传成功 label.uploadsuc
          this.fileId = res.data.fileinfoid

        } else {
          // 上传失败 label.file.upload.fail
          this.$message.warning(this.$i18n.t('label.file.upload.fail'))
        }
      }).catch(() => {
        return false
      })
      
      this.$emit('saveThrData', this.template, this.fileId)
    },
    handleExceed() {
      // 文件个数超出预设，如果需要替换文件，请先删除已上传的文件
      this.$message.warning(this.$i18n.t('vue_label_file_num_limit_tip'))
    }
  },
}
</script>
<style lang='scss' scoped>
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
  width: 100%;
}
::v-deep .el-textarea__inner {
  resize: none;
}
// ::v-deep .el-upload {
//   display: block;
// }

.buttonBox {
  float: right;
}
.button {
  text-align: center;
  width: 80px;
  margin-top: 20px;
  display: inline-block;
}
.errMessage {
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
  color: #c12217;
  letter-spacing: 0;
  line-height: 30px;
}
.updateBox {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  .updatetitle {
    margin-right: 5px;
    float: left;
    height: 21px;
    font-family: SourceHanSansCN-Bold;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
    line-height: 30px;
  }
  .updateBt {
    margin-right: 5px;
    float: left;
  }
  .tips {
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #888888;
    letter-spacing: 0;
    line-height: 30px;
  }
}
.el-textarea__inner {
  // 然后找到对应的类名，在这里将拉伸去掉即可
  resize: none !important;
}
.headline {
  text-align: left;
  letter-spacing: 0;
  line-height: 30px;
}
.top {
  width: 100%;
  font-family: SourceHanSansCN-Bold;
  font-size: 16px;
  color: #080707;
}
.mainBox {
  width: 50%;
  height: 100%;
  margin: 0 auto;
}
</style>