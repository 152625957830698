<!--
 * @Author: zhangyutong
 * @Date: 2021-09-01 19:20:37
 * @LastEditTime: 2022-04-13 17:37:33
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingInvitation\component\stepTwo.vue
-->
<!--  -->
<template>
  <div class="">
    <div class="pull-left" style="width: 100%">
      <!-- 选择要发送邀约码的员工 -->
      <p class="groupTitle">{{$t('c476')}}</p>
    </div>
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :checked="true"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :pict-loading="pictLoading"
      :use-hyperlinks="false"
      @onchange="onchange"
      @append="append"
    >
    </table-panel>
    <!-- 已选择{{ selectedList.length }}个邀约员工  -->
    <div class="selectMessage">{{ $t("c85", { member: selectedList.length }) }}</div>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import * as CommonObjApi from "../../api";

export default {
  components: { TablePanel },
  data() {
    return {
      names: "",
      selectedList: [],
      ids: "",
      noDataTip: false,
      pageObj: {
        dataList: [],
      },
      tableAttr: [],
      pictLoading: false,
      objectApi: "campain",
      objId: "user",
      prefix: "005",
      pageNum: 1, //页码默认为1
      pageSize: 50, // 每页展示数据条数
      totalSize: 0, // 总记录数
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      checkedList: [],
      viewId: "aec20130147550381PLK",
      viewList: [], // 视图列表
      viewLength: 0,
      tagIds: "",
      tableHeight: 400,
      dataarr: [],
    };
  },
  created() {
    this.getViewListHeader();
  },
  watch: {
    selectedList() {
      // eslint-disable-next-line no-self-assign
      this.selectedList = this.selectedList;
    },
  },
  //方法集合
  methods: {
    // 表格选中数据
    onchange(val) {
      this.selectedList = val;
      if (val.length > 0) {
        let ids = "";
        let names = "";
        for (var i = 0; i < val.length; i++) {
          ids += val[i].id + ",";
          names += val[i].name + ",";
        }
        //去掉最后一个逗号(如果不需要去掉，就不用写)
        if (ids.length > 0) {
          ids = ids.substr(0, ids.length - 1);
          names = names.substr(0, names.length - 1);
        }
        this.ids = ids;
        this.names = names;
      } else {
        this.ids = "";
        this.names = "";
      }
    },
    saveTow() {
      this.$emit("saveTowData", this.ids, this.names);
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getViewListData(this.selectedList);
      }
    },
    // 获取视图数据
    getViewListData(rows) {
      // if (this.viewId) {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */
      this.pictLoading = true;
      this.getViewListDataParams = {
        obj: this.prefix,
        viewId: this.viewId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortField === "" ? "" : this.sortDir,
        searchKeyWord: "",
        conditionValues: "",
        ischangenum: "false",
        recordnums: this.pageSize,
      };
      CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
        if (res.result) {
          if (res.data.totalSize > this.pageSize) {
            this.dataarr = [...this.dataarr, ...res.data.list];
            this.pageNum++;
          } else {
            this.dataarr = [...this.dataarr, ...res.data.list];
          }
          this.pageObj.dataList = [...this.dataarr];
          if (rows && rows.length > 0) {
            this.selectedList = rows;
            this.pageObj.dataList.forEach((element) => {
              if (this.selectedList[0].id == element.id) {
                this.selectedList.push(element);
                element.checked = true;
                this.selectedList = this.setdata(this.selectedList);
                this.onchange(this.selectedList);
              }
            });
          }

          this.$refs.tablePanel.dataList = this.pageObj.dataList;
          //判断是否有更多数据
          if (Number(res.data.totalSize) == res.data.list.length) {
            this.noDataTip = true;
          } else {
            this.noDataTip = false;
          }
          this.pictLoading = false;

          // this.$refs.tablePanel.$refs.tablePanel &&
          //   this.$refs.tablePanel.$refs.tablePanel.doLayout();
          // setTimeout(() => {
          //   this.pictLoading = false
          // }, 1000)
          // setTimeout(() => {
          //   this.$refs.tablePanel &&
          //     this.$refs.tablePanel.$refs.tablePanel &&
          //     this.$refs.tablePanel.$refs.tablePanel.doLayout()
          // }, 2000)
          // this.lastRefresh = new Date()
          // this.pageObj.totalSize = res.data.totalSize

          //默认选中
          // if (rows) {
          //   let selectlist = rows.inviteduser;
          //   let selectarr = selectlist.split(',')
          //   let arr = [...res.data.list].filter((x) =>
          //     [...selectarr].some((y) => x.id == y)
          //   ) // 取并集
          //   this.$refs.tablePanel.toggleSelection(arr)
          // }
          // this.pictLoading = false
        } else {
          this.pictLoading = false;
          this.tableAttr = [];
          this.pageObj.dataList = [];
          this.$message.error(res.returnInfo);
        }
      });
    },
    //去重
    setdata(data) {
      for (let i = 0; i < data.length; i++) {
        for (let j = i + 1; j < data.length; j++) {
          if (data[i].id == data[j].id) {
            if (data[i].name) {
              data.splice(j, 1);
              j--;
            } else if (data[j].name) {
              data.splice(i, 1);
              i--;
            }
          }
        }
      }
      return data;
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.pictLoading = true;
      this.tableAttr = [];
      let params = {
        conditionValues: "",
        isAllTag: "",
        obj: "005",
        searchKeyWord: "",
        tagIds: "",
        viewId: "aec20130147550381PLK",
      };
      CommonObjApi.getViewListHeader(params).then((res) => {
        this.addable = res.data.objServices.add;
        this.tableAttr = res.data.headLabel;
        this.tableAttr.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR" ||
            item.schemefieldName === "subject" ||
            item.lookupObj === "user" ||
            item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get("activeTabObjId") === "projectworklist") {
            if (item.schemefieldType === "D") {
              this.$set(this.tableAttr[idx], "click", "detail");
            }
          }
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
        });
        this.$refs.tablePanel &&
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        setTimeout(() => {
          this.pictLoading = false;
        }, 1000);
      });
    },
    //初始化
    init() {
      this.pageObj.dataList = [];
      this.selectedList = [];
      this.ids = [];
      this.names = "";
      this.pageNum = 1;
      this.dataarr = [];
    },
  },
};
</script>
<style lang='scss' scoped>
.selectMessage {
  line-height: 35px;
}
.groupTitle {
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
  width: 100%;
}
</style>